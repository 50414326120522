<template>
  <base-layout>
    <div
      v-if="$acl.canNotView('pass')"
      class="py-5 font-bold text-center text-gray-600"
    >
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
    <template v-else>
      <div v-if="!pass" class="h-screen vld-parent">
        <loading :active="true" :is-full-page="false" :z-index="20" />
      </div>

      <template v-else>
        <div
          class="flex flex-wrap items-center justify-between w-full px-4 py-3 bg-white rounded"
        >
          <div class="flex items-center promotion-header md-max:mt-3">
            <span
              >{{ $t('components.subscriptionManagement.headline') }} /</span
            >
            <h1>
              {{ $t('components.common.details') }} (#{{
                $truncate($route.params.id, -5)
              }})
            </h1>
          </div>
          <div class="flex items-center gap-4">
            <button
              @click="executeAction('notifyRiders')"
              class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
              :class="[
                pass.status === 'Active'
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
              ]"
            >
              <div class="flex items-center ">
                <i class="mr-2 fas fa-paper-plane"></i>
                <span>
                  {{
                    $t('components.subscriptionManagement.action.notifyRider')
                  }}
                </span>
              </div>
            </button>
            <ToggleActions
              :current-status="pass.status"
              @change="onChangePassStatus"
            />
          </div>
        </div>

        <div
          class="flex flex-col px-4 py-12 mt-2 bg-white rounded md:flex-row "
        >
          <section class=" md:w-1/2">
            <pass-card
              :minuteAmountOfPass="pass.free_riding_time_minutes"
              :currencySymbol="currencySymbol"
              :passPrice="pass.amount"
              :days="pass.period"
              :passCategory="pass.category"
              :isUnlockDiscountApplied="!!pass.unlock_charge"
              :unlockDiscount="pass.unlock_charge"
              :isReservationDiscountApplied="!!pass.reservation_charge"
              :reservationDiscount="pass.reservation_charge"
              :isMinuteChargeDiscountApplied="!!pass.per_minute_charge"
              :perMinuteChargeDiscount="pass.per_minute_charge"
              :isFreeMinutesPerRideApplied="!!pass.free_minutes_each_ride"
              :freeMinutesPerRide="pass.free_minutes_each_ride"
              :passStartDate="getPassStartDate"
              :passExpiredDate="getPassExpiredDate"
            />
          </section>
          <section
            class="px-2 mt-10 md:ml-10 md:grid md:grid-cols-3  md:mt-0 md:w-1/2 lg:w-2/3"
          >
            <div
              v-for="(columns, columnsIndex) in getPassDetails"
              :key="columnsIndex"
              class=""
            >
              <div
                v-for="(item, itemIndex) in columns"
                :key="item.text"
                :class="`${itemIndex % 5 === 0 ? '' : 'md:mt-5'}`"
                class="flex flex-row justify-between mt-5 md:mt-0 md:flex-col md:justify-start"
              >
                <div class="w-1/3 md:w-full view-detail-title ">
                  {{ item.text }}
                </div>

                <div
                  v-if="item.id === 'assignedFleet'"
                  class="w-2/3 md:w-full view-detail-value"
                >
                  <div
                    v-if="item.value"
                    class="flex items-center gap-2 flex-wrap"
                  >
                    <RouterLink
                      class="text-blue-600"
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: item.value.id },
                      }"
                      target="_blank"
                      >{{ item.value.name }}</RouterLink
                    >
                  </div>
                  <div v-else class="view-detail-value">
                    <span>--</span>
                  </div>
                </div>

                <div v-else class="w-2/3 md:w-full view-detail-value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </section>
        </div>

        <PassDetailsHistory :package-id="id" />
      </template>
      <NotifyModal
        :id="`notify-${pass.id}`"
        :key="`notify-${pass.id}`"
        :isLoading="isLoading"
        :title="
          $t('components.subscriptionManagement.modals.notifyModal.title')
        "
        :subtitle="
          $t('components.subscriptionManagement.modals.notifyModal.subTitle')
        "
        :cancelButtonText="
          $t('components.subscriptionManagement.modals.notifyModal.cancel')
        "
        :cancelButtonVariant="`secondary`"
        :notify-button-text="
          $t('components.subscriptionManagement.modals.notifyModal.notify')
        "
        :notify-button-variant="`green`"
        @setQueryString="(query) => (queryString = query)"
        @notify="actionNotify"
        @cancel="executeAction('hideNotifyModal')"
      />
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import PassCard from '@/components/cards/PassCard'
import PassDetailsHistory from './PassDetailsHistory'
import ToggleActions from './ToggleActions.vue'
import {
  // getDateDifference,
  // getFormattedDate,
  getFormattedDateTime,
} from '@/utils/datetime'
import { useEndpoints } from '@/composables'
import { getDate, dateZuluFormatter } from '@/utils'
export default {
  name: 'ViewPassDetails',
  components: {
    BaseLayout,
    PassCard,
    PassDetailsHistory,
    ToggleActions,
    NotifyModal: () => import('@/components/modals/NotifyModal'),
  },
  data() {
    return {
      // request state
      req: {
        busy: false,
        failed: false,
        errors: false,
      },

      // pass data -> fetchData()
      pass: null,

      // others
      title: '',
      fleets: '',
      isLoading: false,
      queryString: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    currencySymbol() {
      return (
        this.pass.assigned_fleet.country.currency_symbol ||
        this.$org?.default_currency?.symbol
      )
    },
    getPassStartDate() {
      return this.getDate(this.dateZuluFormatter(this.pass.start_date))
    },
    getPassExpiredDate() {
      return this.getDate(this.dateZuluFormatter(this.pass.end_date))
    },
    getPassDetails() {
      const pass = this.pass

      return [
        // col-1
        [
          {
            id: 'assignedFleet',
            text: this.$t(
              'components.subscriptionManagement.details.passDetails.assignedFleet'
            ),
            value: pass.assigned_fleet ? pass.assigned_fleet : null,
          },
          {
            text: this.$t(
              'components.subscriptionManagement.details.passDetails.updatedAt'
            ),
            value: getFormattedDateTime(pass.updated_at),
          },
        ],
        // col-2
        [
          {
            text: this.$t(
              'components.subscriptionManagement.details.passDetails.vehicleType'
            ),
            value: this.getPassVehicleType(pass.vehicle_type),
          },
        ],
        // col-3
        [
          {
            text: this.$t(
              'components.subscriptionManagement.details.passDetails.createdAt'
            ),
            value: getFormattedDateTime(pass.created_at),
          },
        ],
      ]
    },
  },
  async created() {
    this.fetchData()
  },
  methods: {
    getDate,
    dateZuluFormatter,
    async actionNotify() {
      this.isLoading = true
      const url =
        useEndpoints.pass.notifyRiders(this.pass.id) + this.queryString

      await this.$http
        .post(url)
        .then((res) => {
          console.log('notifySuccess--', { res })
          this.$modal.hide(`notify-${this.pass.id}`)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successful',
              text: res.data.message,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('notifyErr--', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Error Occurred [${err.response.status}]`,
              text: err.response.data.message,
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    executeAction(type) {
      if (this.$acl.canNotUpdate('pass')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'notify',
            permission: 'pass',
          })
        )
        return
      }
      if (type === 'notifyRiders') {
        if (['Expired', 'Inactive'].includes(this.pass.status)) {
          if (this.pass.status === 'Expired') {
            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Expired Pass!',
                text: "You can't notify riders for expired Pass.",
              },
              5000
            )
          }
          if (this.pass.status === 'Inactive') {
            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Inactive Pass!',
                text:
                  "You can't notify riders for inactive Pass. Please activate the Pass first.",
              },
              5000
            )
          }
          return
        } else {
          this.$modal.show(`notify-${this.pass.id}`)
        }
      }
      if (type === 'hideNotifyModal') {
        this.$modal.hide(`notify-${this.data.id}`)
      }
    },
    async fetchData() {
      await this.$http
        .get(`/dashboard/passes/${this.id}/`)
        .then(({ data }) => {
          this.req.busy = false
          this.pass = data
        })
        .catch((err) => console.log(err))
    },
    getPassType(opt) {
      const map = {
        DD: 'Daily',
        WE: 'Weekly',
        MM: 'Monthly',
        YY: 'Yearly',
      }
      return map[opt] || '--'
    },
    getPassVehicleType(opt) {
      const map = {
        P: 'Scooter',
        S: 'Bike',
        E: 'EBike',
        A: 'All',
      }
      return map[opt] || '--'
    },
    getPassProfileType(opt) {
      const map = {
        S: 'Student',
        P: 'Professional',
        A: 'All',
      }
      return map[opt] || '--'
    },
    arrayToCommaSeparated(arr) {
      if (!Array.isArray(arr) || arr.length === 0) return '--'

      return arr.join(', ')
    },
    async onChangePassStatus(e) {
      if (this.$acl.canNotUpdate('pass')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'pass',
          })
        )
        return
      }
      const data = new FormData()
      data.append('is_active', e)
      console.log('pass-status', e)
      await this.$http
        .patch(useEndpoints.pass.details(this.id), data)
        .then(() => {
          this.fetchData()
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Pass Status Updated',
            text: 'Pass status updated successfully',
          })
        })
        .catch((err) => {
          // this.pass.is_active = false
          console.log('pass-err', { err }, err.response.data.message)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Failed [Error:${err.response.status}]`,
            text: `${err.response.data.message}`,
          })
          console.log(err)
        })
        .finally()
    },
  },
}
</script>

<style lang="scss" scoped>
.pass-status {
  width: 230px;
  height: 50px;
  background: white;
  box-shadow: 0px 0px 4px 2px #e4dfdf;
  border-radius: 25px;
  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #15151b;
    margin-right: 8px;
  }
  t-toggle {
    font-size: 20px;
  }
  h3 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e39;
  }
}
</style>
